@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    /* font-krona: Krona One, sans-serif; */
  }
}


html,
body {
  height: 100vh;

}

#__next {
  height: 100%;
  /* background-color: #373afd; */

  }
